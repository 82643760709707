<template>
  <div class="myPromotion">
    <mt-header :title="$t('myPromotion')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div v-if="!list.length" class="listNull">
        <img src="../../assets/empty.png" height="117px" style="margin-top: 50px;">
        <div class="tips">{{ $t('noInviteFriends') }}</div>
      </div>
      <template v-else>
        <div v-for="(item, index) in list" :key="index" class="wrap">
          <img class="logo" src="../../assets/logo.png">
          <div class="content">
            <div>{{ item.phone }}</div>
            <div>{{$t('myPromotionTips')}} {{item.registerDate}}</div>
          </div>
        </div>
      </template>
      <div class="bottom">
        <mt-button type="primary" @click="$router.push('/mine/inviteFriends')">{{
          $t("inviteFriendsImmediately")
        }}</mt-button>
      </div>
    </div>
  </div>
</template>

<script>
import { myRecommend } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
export default {
  name: 'MyPromotion',
  components: {
    callPhone
  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.getMyRecommend()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getMyRecommend() {
      await this.$axios({
        method: "get",
        url: myRecommend,
        params: {
          level: '1',
        }
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.list = e.body
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.myPromotion {
  > .content {
    padding: 20px 0px;
    .listNull {
      text-align: center;
      .icon {
        font-size: 200px;
        margin: 40px 10px 10px 10px;
      }
      .tips {
        color: #868686;
      }
    }
    .wrap{
      padding: 10px 17px;
      border-top: 1px solid #E7E7E7;
      border-bottom: 1px solid #E7E7E7;
      font-size: 14px;
      color: #7A7A7A;
      display: flex;
      .logo {
        width: 41px;
        margin-right: 17px;
      }
      .content {
        flex: 1;
        div {
          &:first-child {
            font-weight: 500;
            font-size: 16px;
            color: #3E3E3E;
          }
        }
      }
    }
  }
  .bottom {
    padding: 40px 20px 20px 20px;
    text-align: center;
    .mint-button {
      border-radius: 6px;
      padding: 0 22px;
    }
  }
}
</style>